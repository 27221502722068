  
import {config} from 'react-spring'
import {keyframes} from 'styled-components'

export const spreadTopRowSlice =  (row_height) => (primary, rest, i) => ({
    from: { 
    width: i === rest[0] ? 500 : [primary, ...rest].includes(i) ? 265 : 0,
    height: i === rest[0] ? row_height - 100 : row_height,   
    opacity: 0,      
    backgroundPositionY: -50
    },
    width: i === primary ? 500 : rest.includes(i) ? 265 : 0, 
    height: i === rest[0] ? row_height - 100 : row_height,   
    opacity: 1,
    backgroundPositionY: 50,
    config: { mass: 1, tension: 170, friction: 26 }
})


export const spreadTopRowSliceCSS = (primary, rest, i) => ({
    keyframes:  keyframes`
    from { 
        width: ${i === rest[0] ? 500 : [primary, ...rest].includes(i) ? 265 : 0}px;
        height: ${i === rest[0] ? 300 : 400}px;
        background-position-y: -50px;
    }

    to {
        width: ${i === primary ? 500 : rest.includes(i) ? 265 : 0}px;
        height: ${i === rest[0] ? 300 : 400}px;
        background-position-y: 50px;
    }
`,
from: { 
    width: i === rest[0] ? 500 : [primary, ...rest].includes(i) ? 265 : 0,
    height: i === rest[0] ? 300 : 400,   
    opacity: 0,      
    backgroundPositionY: -50
},
to:  { 
    width: i === primary ? 500 : rest.includes(i) ? 265 : 0, 
    height: i === rest[0] ? 300 : 400, 
    opacity: 1,
    backgroundPositionY: 50,
    }
}) 


export const spreadBottomRowSlice = (row_height) => (primary, rest, i) => ({
    from: { 
    width: i === rest[0] ? 500 : [primary, ...rest].includes(i) ? 265 : 0,
    height: i === rest[0] ? row_height : row_height -100, 
    top: i === rest[0] ? -100 : 0,   
    opacity: 0,      
    backgroundPositionY: -50
    },
    width: i === primary ? 500 : rest.includes(i) ? 265 : 0, 
    height: i === rest[0] ? row_height : row_height -100, 
    top: i === rest[0] ? -100 : 0,   
    opacity: 1,
    backgroundPositionY: 50,
    config: { mass: 1, tension: 170, friction: 26 }

})

    
  