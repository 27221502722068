import React, {useState, useEffect} from "react"
import Slider from '../components/Slider'

import {Link, graphql, navigate} from 'gatsby'
import {isMobile, pwindow} from '../utils'
import {useSprings, animated as a} from 'react-spring'
import styled from 'styled-components'
import Seo from '../components/Seo'





export default function Home(props) {
  if(isMobile()) {
    navigate('/mobile')
  }
  const {data: { allMongodbCmsCategories, allMongodbCmsProjects} } = props
  const categoiries = allMongodbCmsCategories.nodes
  const projects = allMongodbCmsProjects.nodes
    .map(({categories_uuid, project_name, project_slug, images, order}) => 
      ({
        project_name, 
        project_slug, 
        categories: categoiries.filter(c => categories_uuid && categories_uuid.includes(c.category_uuid)),
        src: images && images.filter(i => i.order === 1).map(i => i.uris['_600']).pop(),
        order

      }))

    projects.sort((a, b) => a.order - b.order)
    return isMobile() 
    ? null 
    : <>
      <Seo title={"NAICE architects"} description={"Commercial, Residential, Installations architecture projects"}>
  
        {[...projects.slice(0,3), ...projects.slice(4,7)].map(p => <link rel="prefetch" href={p.src}/>)}
  
        </Seo>
      <Slider projects={projects} />
    </>
           
  
  }



export const pageQuery = graphql`

  query {
    allMongodbCmsProjects {
      nodes {
        project_slug
        project_name
        categories_uuid
        order
        images {
          uris {
            _600
          }
          order
        }
      }
    }
    allMongodbCmsCategories {
      nodes {
        label
        category_uuid
      }
    }
  }

`

