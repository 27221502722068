import { navigate } from 'gatsby';
import React, {useState, useEffect, useRef} from 'react'
import {useSprings, useSpring, animated as a, config} from 'react-spring'
import styled, {keyframes, css as componentscss} from 'styled-components'
import {pwindow, isMobile} from '../utils'
import {Link} from 'gatsby'
import css from '@styled-system/css'

import {spreadTopRowSlice, spreadTopRowSliceCSS, spreadBottomRowSlice} from './DesctopTeaserShow'
const opacity = keyframes`
  0% {
    opacity: 0;
  }

 
  100% {
    opacity: 1;
  }
`;


const Wr = styled.div`
display: flex;
.mask {

  &:hover {
    cursor: pointer;    
  }

  .mask_content {
    margin: 5px;
  }
  
  position: relative;
  overflow: hidden;
  .img_container {
    position: absolute;
    width: 100%;
    height: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  h3 {
    position: absolute;
    left: 0px;
    top:0px;
    z-index: 2;
    margin: 15px;
    font-size: 1.5rem;
    padding: 10px;
    background: #fff;
    opacity: 0.9;


    .circle {
      border: 3px solid;
      width: 1px;
      height: 1px;
      position: absolute;
      top: 10px;
      left: 10px;
      color: white;
      z-index: 2;
    }

    .title {
      margin: 5px;
      color: black;
      position: relative;
      z-index: 3;
    }

    
    .categorie {
        background: black;
        color: white;
        padding: 10px;
      position: absolute;
      font-size: 1rem;
      top: 50px;
      left: 20px;
      font-weight: 400;
      z-index: 4;

    }
  }

  .arrow {
    position: absolute;

    width: 200px;
    height: 10px;
    z-index: 3;
    

    top: calc(50% - 5px);
    left: calc(50% - 50px);
    opacity: 0.8;

  }
}

`

const TeaserRow = ({imgs, visible_length, spreadSlide}) => {

  const ref = useRef()
  const [{indicies, step}, setIndicies] = useState({
    indicies: [...Object.keys(imgs)].map(i => parseInt(i)),
    step: 0 % (imgs.length - 1)
  })

  const [primary, ...rest] = indicies.slice(0, visible_length)
  const hidden = indicies.slice(visible_length)

  const forwardStep = () => {
    const new_step = (step + 1)%(visible_length)

      const [nextPrimary, ...restSecondaries] = rest
      const [firstHidden, ...restHidden] = hidden
    
      const new_indicies = new_step === 0 
      ? [firstHidden, ...restSecondaries,  ...restHidden,  ...(typeof(nextPrimary) !== "undefined" ? [nextPrimary, primary] : [primary])].slice(0, imgs.length) 
      : [...rest, primary, ...hidden]


      setIndicies({
        indicies: new_indicies,
        step: new_step
      })
  }
  
  const update = (i) => spreadSlide(primary, rest, i)    

  const [springs, set] = useSprings(imgs.length, update)

  set(update)

  useEffect(() => {

    const interval = setInterval(() => {

      forwardStep()      
      
    }, 2000)


    return () => clearInterval(interval)

  }, [indicies])

  useEffect(() => {

    forwardStep()      

  }, [ref.current])

  return <Wr ref={ref}>
    {imgs.map((t, i) => {
      return <Card key={`${pwindow.innerHeight}_${i}`} {...{spring: springs[i], t, step}} />
    })}
  </Wr>

}



const DesctopTeaserShowWrapper = styled.div`

animation: ${opacity} 2s ease;
height: 750px;
overflow: hidden;
`

const DesctopTeaserShow = ({projects}) => {

  const [show, setShow] = useState(false)
  const _rowHeight = pwindow.innerHeight ? (pwindow.innerHeight - 100)/2: 400
  const height  = _rowHeight < 400 ? _rowHeight : 400

  pwindow && Promise.all(projects.map(({src}) => new Promise((resolve, _) => {
      const imageLoader = new Image();
      imageLoader.src = src;
      imageLoader.onload = resolve
    })))
    .then(() => setShow(true))

  return <>

    
    <DesctopTeaserShowWrapper >
    
    {show 
    ? <>
      <TeaserRow imgs={projects.slice(0,4)} key={`top_${height}`} visible_length="3" spreadSlide={spreadTopRowSlice(height)} />
      <TeaserRow imgs={projects.slice(4,8)}  key={`bottom_${height}`}  visible_length="3" spreadSlide={spreadBottomRowSlice(height)} />
      </>
    : null}

  </DesctopTeaserShowWrapper></>

}

const Card = ({spring, t}) => {

  const [hovered, setHover] = useState(false)
  
  const [{width, top, left}, set]= useSpring(() => ({
    from: { width: hovered ? 3 : 300, left: 0, top:0},
    width: hovered ? 600 : 0,
    left: hovered ? -600 : 0,
    top: hovered ? -600 : 0,

  }))

  
  if(hovered) set({width: 600, left: -600, top: -600})
  else set({width: 0, left: 60, top: 30})



  return  <div onMouseOver={(e) => {setHover(true)}} onMouseLeave={(e) => setHover(false)} onClick={() => navigate(`/projects/${t.project_slug}`)}>
    <a.div  className="mask" style={{width: spring.width.interpolate(w => w), height: pwindow.innerHeight ?  spring.height.interpolate(w => w) : null, top: spring.top ? spring.top.interpolate(w => w) : null }}>
      <div className="mask_content">
      <h3>
      <a.div className="circle" style={{ top: top, left: left, borderWidth: width.interpolate(w => `${w}px`)}}/>
      <span className="title">{t.project_name}</span>
      {hovered ? <span className="categorie">{t.categories[0] && t.categories[0].label}</span> : null}
    </h3>
  <a.div className="img_container" style={{backgroundImage: `url(${t.src})`, backgroundPosition: spring.backgroundPositionY.interpolate(y => `50% ${y}%`) }}></a.div>
  {hovered ? <a.div className="arrow" style={{}} /> : null}
  </div>
  </a.div></div>
}




export default ({projects}) => {
  return <DesctopTeaserShow {...{projects}} />
}